<template>
  <div class="message">
    <div class="navTitle"><img src="../assets/img/arrl.png" alt="" @click="goBack">消息</div>
    <router-link to="/newsContent/1" class="card">
      <div class="card-title flex">
        <img src="../assets/img/msg1.png" alt="">
        <p class="titleText">融资贷款</p>
        <p class="titleDay">昨天</p>
        <span>1</span>
      </div>
      <!-- <div class="card-content">
        您的政采贷以及申请成功
      </div> -->
      <div class="card-content2 flex">
          <div class="p"> 
            <p class="p2">您的政采贷以及申请成功</p>
          </div>         
      </div>
    </router-link>
    <router-link to="/newsContent/2" class="card">
      <div class="card-title flex">
        <img src="../assets/img/msg2.png" alt="">
        <p class="titleText">产品推荐</p>
        <p class="titleDay">昨天</p>
        <span>6</span>
      </div>
      <div class="card-content2 flex">
          <div class="p">
            <p class="p1">招商银行闪电贷</p>
            <p class="p2">专门为中小企业定制</p>
          </div>
          <img src="../assets/img/yhcp2.png" alt="">
      </div>
    </router-link>
    <router-link to="/newsContent/3" class="card">
      <div class="card-title flex">
        <img src="../assets/img/msg3.png" alt="">
        <p class="titleText">最新政策</p>
        <p class="titleDay">09/26</p>
        <span>1</span>
      </div>  
      <div class="card-content2 flex">
          <div class="p"> 
            <p class="p2">政府扶持政策最新</p>
          </div>         
      </div>   
    </router-link>
    <div class="over">— 到底啦 —</div>
    <div style="height: 15vw; width:100%"></div>
    <TabBar nav="message"/>
  </div>
</template>
<script>
import TabBar from '../components/TabBar.vue';
export default {
  setup() {
  },
  methods:{
    goBack(){
        this.$router.go(-1);//返回上一层
    },
  },
  components: {
    TabBar
  }
}
</script>
<style lang="less" scoped>
.message {
  font-size: 4vw;
  padding-top: 12.5vw;
  .navTitle {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1001;
    background: linear-gradient(to right, #65b8fc, #8193ff);
    height: 12.5vw;
    text-align: center;
    line-height: 12.5vw;
    color: #fff;
    img {
        height: 4vw;
        position: absolute;
        left: 4vw;
        top: 4.3vw;
    }
  }
  .card {
    display: block;
    color: #333;
    margin: 4vw;
    background-color: #fff;
    border-radius: 10px;
    padding: 4vw;
    .card-title {
      margin-bottom: 4vw;
      img {
        width: 7.8vw;
      }
      .titleText {
        font-size: 4.8vw;
        font-weight: 600;
        flex: 1;
        padding: 0 2vw;
      }
      .titleDay {
        font-size: 3.2vw;
        color: #666666;
      }
      span {
        color: #fff;
        background-color: #ff6600;
        font-size: 3.5vw;
        padding: 0 1vw;
        border-radius: 100%;
        margin-left: 1vw;
        text-align: center;
        min-width: 2.5vw;
      }
    }
    .card-content {
      color: #666666;
      margin: 4vw 0;
    }
    .card-content2 {
      .p {
        flex: 1;
        .p1 {
          font-size: 4.5vw;
          font-weight: 600;
          margin-bottom: 1vw;
        }
        .p2 {
          color: #666;
        }
      }
      img {
        width: 28.8vw;
        height: auto;
        border-radius: 1.5vw;
      }
    }
  }
  .over {
    text-align: center;
    color: #999;
  }
}
</style>
